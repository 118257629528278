<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{this.isEdit?l('Edit'):l('Create')}}</span>
            </div>
        </div>
        <div>
            <a-config-provider :locale="zh_CN">
                <a-row>
                    <a-col :span="24">
                        <a-form-item required :label-col="{ span: 5 }" :wrapper-col="{ span: 8 }" :label="l('organization')">
                            <a-tree-select
                                    v-model="orgId"
                                    style="width: 200px"
                                    :tree-data="treeDataList"
                                    :replace-fields="treeReplaceFields"
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto',width: '300px' }"
                                    :placeholder="l('please_select_organization_structure')"
									:dropdownMatchSelectWidth="false"
                                    allow-clear
                                    tree-default-expand-all
                            >
                            </a-tree-select>
                        </a-form-item>
                    </a-col>


                    <a-col :span="24">
                        <a-form-item required :label-col="{ span: 5 }" :wrapper-col="{ span: 8 }" :label="l('Users')">
							<a-select showSearch
                                      style="width: 200px;text-align: left;"
                                      dropdownClassName="drop-down-Style"
                                      :dropdownMatchSelectWidth="false"
                                      v-model="userid"
                                      :placeholder="l('please_enter_your_username_to_search')"
                                      allow-clear
                                      optionFilterProp="label"
                                      >
                                <a-select-option v-for="item in userlist" :key="item.id" :label="item.realName">
                                    {{ item.realName }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>


                    <a-col :span="24">
                        <a-col :span="5">
                            <a-form-item :label="l('layoutuser.isresponsible')">
                            </a-form-item>
                        </a-col>
                        <a-col :span="5">
                            <a-switch style="margin-top: 8px" :checked-children="l('Yes')" :un-checked-children="l('No')"
                                      v-model="IsResponsible" @change="select1"/>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item :label="l('layoutuser.isauditor')">
                            </a-form-item>
                        </a-col>
                        <a-col :span="5">
                            <a-switch style="margin-top: 8px" :checked-children="l('Yes')" :un-checked-children="l('No')"
                                      v-model="IsAuditor"/>
                        </a-col>
                    </a-col>

                    <!--<a-col :span="24">
                        <a-form-item  :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }" label="审核类型">
                        <a-tree-select
                                v-model="auditItemTypeId"
                                style="width: 100%"
                                :tree-data="ItemTypetreeData"
                                :replace-fields="treeReplace"
                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                placeholder="请选择审核项类型..."
                                allow-clear
                                showSearch
                                tree-default-expand-all
                        >
                        </a-tree-select>
                        </a-form-item>
                    </a-col>-->

                    <a-col :span="24">
                        <a-form-item  :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }" :label="l('Remark')">
                        <a-textarea  style="width: 100%" :auto-size="{minRows:2, maxRows:2,}" v-model="remark"></a-textarea>
                        </a-form-item>
                    </a-col>


                    <a-col :span="24">
                        <a-col :span="5">
                            <a-form-item :label="l('Key_positions')">
                            </a-form-item>
                        </a-col>
                        <a-col :span="5">
                            <a-switch style="margin-top: 8px" :checked-children="l('Yes')" :un-checked-children="l('No')"
                                      v-model="IsKeyPositions"/>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item :label="l('Monthly_hazard_indicators')">
                            </a-form-item>
                        </a-col>
                        <a-col :span="5">
                            <a-input-number v-model="MonthlyIssueIndicators" :placeholder="l('Please_enter_the_quantity')" style="width: 100%;">
                                </a-input-number>
                        </a-col>
                    </a-col>


                </a-row>
            </a-config-provider>
        </div>
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>
    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import {LayoutOrgServiceProxy, LayoutUserServiceProxy,EmployeeServiceProxy,AuditItemtypeServiceProxy,LayoutUserEditDto,APPApiServiceProxy} from '../../../../shared/service-proxies';
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";

    let _this;
    export default {
        name: 'creatoredit-layoutuser',
        mixins: [ModalComponentBase],
        components: {

        },
        created() {
			_this = this;
            this.fullData(); // 模态框必须,填充数据到data字段
            this._LayoutOrgServiceProxy = new LayoutOrgServiceProxy(this.$apiUrl, this.$api);
            this._LayoutUserServiceProxy=new LayoutUserServiceProxy(this.$apiUrl, this.$api);
            this._EmployeeServiceProxy=new EmployeeServiceProxy(this.$apiUrl, this.$api);
			this.APPApiServiceProxy=new APPApiServiceProxy(this.$apiUrl, this.$api);
            this._AuditItemtypeServiceProxy=new AuditItemtypeServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            if(this.isEdit){
                this.getData();
            }else {
				this.getData1();
			}
            this.getTreeData();
            this.getuser();
        },
        data() {
            return {
                zh_CN,
                id:undefined,
                isEdit: false,//是否是编辑
                isLook: false,//是否是查看
                userlist:[],
                userid:undefined,
                orgId: undefined,
                IsResponsible:true,
                IsAuditor:true,
                treeDataList: [],
                treeReplaceFields: {
                    title: 'levelNameAndName',
                    key: 'id',
                    value: 'id'
                },
                treeReplace:{
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                ItemTypetreeData:[],
                auditItemTypeId:undefined,
                remark:undefined,
                entity:new LayoutUserEditDto(),
				isclick: true,
                IsKeyPositions:false,
                MonthlyIssueIndicators:undefined
            }
        },
        methods: {
            getData() {
                this.isLoading = true;
                this._LayoutUserServiceProxy.getForEdit(
                    this.id
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
					this.orgId=res.orgId;
                    this.userid=res.userId;
                    this.IsResponsible=res.isResponsible;
                    this.IsAuditor=res.isAuditor;
                    this.remark=res.remark;
                    this.IsKeyPositions = res.isKeyPositions;
                    this.MonthlyIssueIndicators = res.monthlyIssueIndicators;

                })
            },
			getData1() {
				this.orgId = this.Orgid;
			},
            getTreeData() {
                this.isLoading = true;
                this._LayoutOrgServiceProxy.getAllData(
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        this.treeDataList = JSON.parse(JSON.stringify(res));
                    })
            },

            getuser(){
				this._EmployeeServiceProxy.getAllEmp().finally(()=>{

					})  .then((res)=>{
						this.userlist=res;
				})
            },

            select1(){
              console.log(this.IsResponsible);
            },

            save() {
                if (this.orgId==null||this.orgId=="") {
                    abp.message.info('组织架构不能为空');
                    return;
                }
				
                if (this.userid==null||this.userid=="") {
                    abp.message.info('用户不能为空');
                    return;
                }

                this.entity.id=this.id;
                this.entity.orgId=this.orgId;
                this.entity.userId=this.userid;
                this.entity.isResponsible=this.IsResponsible;
                this.entity.isAuditor=this.IsAuditor;
                this.entity.remark=this.remark;
                this.entity.isKeyPositions=this.IsKeyPositions;
                this.entity.monthlyIssueIndicators=this.MonthlyIssueIndicators;

                var LayoutUserEditDto = {layoutUserEditDto:this.entity};
				if(this.isclick) {
					this.isclick = false;
					this._LayoutUserServiceProxy.createOrUpdate(
					    LayoutUserEditDto
					).finally(() => {
					    this.close()
					}).then(res => {
						this.isclick = true;
					    this.success(true)
					})
				}
            },

        }
    }
</script>

<style>
</style>
